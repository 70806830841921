













































import { Vue, Component, Prop } from "vue-property-decorator";
import { MobileProfileProperties } from "./MobileProfileView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import ProfileService from "@/services/ProfileService";
import ExceptionUtil from "@/utilities/ExceptionUtil";

@Component({
    components: { EditorControlView },
    data: () => ({
        saving: false
    })
})
export default class MobileProfileInfoView extends Vue {
    @Prop() private properties: MobileProfileProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private editorControl = new EditorControlProperties();
    private profileService = new ProfileService();

    public get genders() {
        return [
            {
                text: this.$t("text.male"),
                value: "Male"
            },
            {
                text: this.$t("text.female"),
                value: "Female"
            }
        ];
    }

    public get user() {
        return this.properties.user;
    }

    public async created() {
        this.editorControl.createVisible = false;
        this.editorControl.deleteVisible = false;
        this.editorControl.cancelVisible = false;

        await this.properties.events.fire("reload");
    }

    public async save() {
        try {
            const r = await this.profileService.put(this.properties.user, false, null, null);
            await this.properties.events.fire("reload");
            AlertDialogProperties.saved(this.alertDialog, null, null);
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async control(button: string) {
        if (button === "save") {
            await this.save();
        }
    }
}
